<template>  
  <div class="transit" >
    <div class="ringtitle">
      <div class="tt">{{ringtitles[0]}}</div>
      <div class="tt">
        {{ringtitles[1]}}
      </div>
      <div class="tt">
        <!-- {{jkljkl}}评分 -->
      </div>
    </div>
    <div class="department">
      <div class="departHeader">
        <div>
          <div>科室</div>
        </div>
        <div v-for="(item,index) in Gear" :key="index">
          <div class="dangout">
            <div class="dangyi">
              <div v-if="item.F_rownumber==1">一档</div>
              <div v-else-if="item.F_rownumber==2">二档</div>
              <div v-else-if="item.F_rownumber==3">三档</div>
              <span>{{item.F_count}}个</span></div>
            <div class="danger">{{item.F_high}}-{{item.F_low}}分</div>
          </div>
        </div>
      </div>
      <div class="deparList">
        <div class="ListIn" v-for="(item,index) in departmentList"  :key="index">
          <div class="LIstName">
            <div>
              {{item.F_DepName}}
            </div>
          </div>
          <div class="rightin" @click="junpll(1,index,item)">
            <input type="text" readonly="readonly" :class="item.one.split('分')[0]>=90?'qualified':'Unqualified'" v-model="item.one" v-if="item.one!=''">
            <input type="text" readonly="readonly" v-model="item.one" v-else-if="item.one===''">
          </div>
          <div class="rightin" @click="junpll(2,index,item)">
            <input type="text" readonly="readonly" :class="item.two.split('分')[0]>=80?'qualified':'Unqualified'" v-model="item.two" v-if="item.two!=''">
            <input type="text" readonly="readonly" v-model="item.two" v-else-if="item.two===''">
          </div>
          <div class="rightin" @click="junpll(3,index,item)">
            <input type="text" readonly="readonly" :class="item.three.split('分')[0]>=70?'qualified':'Unqualified'" v-model="item.three" v-if="item.three!=''">
            <input type="text" readonly="readonly" v-model="item.three" v-else-if="item.three===''">
          </div>
        </div>
      </div>
    </div>
    <div class="submit" @click="gosubmit()" v-if="!iskeybord&&tijiaoindex==0">
      提交
    </div>
    
    <div :class="iskeybord?'keyboard longs':'keyboard'" @click.stop @click="PuKeyboard()">
      
      <div :class="iskeybord?'ppu ':'ppu'" @click.stop>
        <div class="confirmds" @click="confirmqq()">确定</div> 
        <div class="pupu">
          <div class="scroll" v-if="inputindex==1">
            <div :class="oneindex==index?'sb inindex':'sb'" v-for="(item,index) in pupnum1" :key="index" @click="chanindex(item,index,1)">
              <div :class="item.type?'':'opacilk'">
                {{item.num}}
              </div>
            </div>
          </div>
          <div class="scroll" v-if="inputindex==2">
            <div :class="oneindex==index?'sb inindex':'sb'" v-for="(item,index) in pupnum2" :key="index" @click="chanindex(item,index,2)">
              <div :class="item.type?'':'opacilk'">
                {{item.num}}
              </div>
            </div>
          </div>
          <div class="scroll" v-if="inputindex==3">
            <div :class="oneindex==index?'sb inindex':'sb'" v-for="(item,index) in pupnum3" :key="index" @click="chanindex(item,index,3)">
              <div :class="item.type?'':'opacilk'">
                {{item.num}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tijiao" @click="gotransfer()" v-if="pupwindow">
      <div class="onkl" v-if="gajklas">
        <img src="../assets/success.png" alt="">
        <div class="success">提交成功</div>
        <div class="Countdown">{{Countdown}}s</div>
      </div>
      <div class="onkl" v-else>
        <div class="onqqq">!</div>
        <div class="success">提交失败</div>
        <div class="places">请联系管理员</div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive,onMounted,onUnmounted,ref,watch} from 'vue'
import { toRefs } from '@vue/reactivity'
import {useRoute, useRouter} from "vue-router";
import { ElMessage } from 'element-plus'
import {getTodaySummary} from '../api/index.js'
import setPromiseInterval, { clearPromiseInterval } from 'set-promise-interval'
import {
  countlist,
  deplist,
  savescore,
  savescorelist
} from '../api/index.js'
export default {
  name: 'App',
  components: {
  },
  setup(){
    const obj = reactive(
      {
        ishint:true,
        ringtitles:['鹿城区综合行政执法局','2022年民主评议打分表'],
        process:[
          {name:"综合科室组",type:1},{name:"科室名称侧",type:1},
          {name:"科室名称侧",type:2},{name:"科室名称侧",type:2},{name:"科室名称侧",type:2},
        ],
        departmentList:[
        ],
        keybord:[1,2,3,4,5,6,7,8,9,0,'.'],
        iskeybord:false,
        listindex:0,
        inputindex:0,
        gajklas:false,
        pupwindow:false,
        Countdown:10,
        Gear:[],
        jkljkl:'',
        istrue:true,
        dangci1:0,
        dangciBeyond1:true,
        dangci2:0,
        dangciBeyond2:true,
        dangci3:0,
        dangciBeyond3:true,
        pupnum1:[
          {num:100,type:true},
          {num:99,type:true},
          {num:98,type:true},
          {num:97,type:true},
          {num:96,type:true},
          {num:95,type:true},
          {num:94,type:true},
          {num:93,type:true},
          {num:92,type:true},
          {num:91,type:true},
          {num:90,type:true},
        ],
        pupnum2:[
          {num:89,type:true},
          {num:88,type:true},
          {num:87,type:true},
          {num:86,type:true},
          {num:85,type:true},
          {num:84,type:true},
          {num:83,type:true},
          {num:82,type:true},
          {num:81,type:true},
          {num:80,type:true},
        ],
        pupnum3:[
          {num:79,type:true},
          {num:78,type:true},
          {num:77,type:true},
          {num:76,type:true},
          {num:75,type:true},
          {num:74,type:true},
          {num:73,type:true},
          {num:72,type:true},
          {num:71,type:true},
          {num:70,type:true},
        ],
        oneindex:1000,
        onevalue:'',
        quedinglkj:0,
        pupindex:0,
        isrefresh:false,
        tijiaoindex:0,

      }
    )
    const route = useRoute();
    //点开键盘
    function junpll (i,index,item){
      if(i==1){
        for(var o=0;o<obj.pupnum1.length;o++){
          if(item.one.split('分')[0] === JSON.stringify(obj.pupnum1[o].num)){
            obj.pupnum1[o].type = true
            obj.pupindex = o
          }
        }
      }else if(i==2){
        for(var o=0;o<obj.pupnum3.length;o++){
          if(item.two.split('分')[0] === JSON.stringify(obj.pupnum2[o].num)){
            obj.pupnum2[o].type = true
            obj.pupindex = o
          }
        }
        
      }else if(i==3){
        for(var o=0;o<obj.pupnum3.length;o++){
          if(item.three.split('分')[0] === JSON.stringify(obj.pupnum3[o].num)){
            obj.pupnum3[o].type = true
            obj.pupindex = o
          }
        }
        
      }
      // alert(i)
      obj.iskeybord = true
      obj.inputindex = i
      obj.listindex = index
      var sequence = obj.inputindex
      if(sequence==1){
        obj.departmentList[index].one = obj.departmentList[index].one.split('分')[0]
      }else if(sequence==2){
        obj.departmentList[index].two = obj.departmentList[index].two.split('分')[0]
      }else if(sequence==3){
        obj.departmentList[index].three = obj.departmentList[index].three.split('分')[0]
      }
    }
    function PuKeyboard (){
      obj.iskeybord = false
      var sequence = obj.inputindex
      var listindex = obj.listindex
      if(sequence==1){
        obj.departmentList[listindex].one = ''
        // obj.pupnum1[obj.pupindex].type = false
      }else if(sequence==2){
        obj.departmentList[listindex].two = ''
        // obj.pupnum2[obj.pupindex].type = false
      }else if(sequence==3){
        obj.departmentList[listindex].three = ''
        // obj.pupnum3[obj.pupindex].type = false
      }
      
    }
    //确定分数
    function confirmqq (){
      if(obj.oneindex==1000){
        ElMessage.error('请选择打分数字')
        return false
      }
      var sequence = obj.inputindex
      var listindex = obj.listindex
      var resuly =  obj.departmentList[listindex]
      var listdep = obj.departmentList
      //档次1
      if(sequence==1){
        //判断是否超出规定
        var kikjl =0
        resuly.one = JSON.stringify(obj.onevalue)
        for(var i = 0;i<listdep.length;i++){
          
          if(i!=listindex){
            //resuly.one
            if(resuly.one>=(parseInt(listdep[i].one)-1)&&resuly.one<=(parseInt(listdep[i].one)+1)){
              ElMessage.error('每个档位分数差距不能低于2分')
              // break
              return false
            }
          }
          
          if(listdep[i].one !=''){
            kikjl+=1
            if(kikjl>obj.dangci1){
              obj.dangciBeyond1 = false
            }
          }
        }
        //判断打分档次是否超出
        if(obj.dangciBeyond1){
          if(obj.onevalue<=100&&obj.onevalue>=90){
            
            resuly.F_Result=obj.onevalue
            resuly.one=obj.onevalue
            resuly.one+='分'
            
            resuly.two=''
            resuly.three=''
            localStorage.setItem('departmentList',JSON.stringify(obj.departmentList))
          }else{
            ElMessage.error('一档分数在100分至90分区间内')
            // resuly.one=''
            var dsaa = JSON.parse(localStorage.getItem('departmentList'))
            if(dsaa){
              obj.departmentList = dsaa
            }else{
              resuly.one=''
            }
            
          }
        }else{
          ElMessage.error('一档只能有'+obj.dangci1+'个')
          obj.dangciBeyond1 = true
          var dsaa = JSON.parse(localStorage.getItem('departmentList'))
          if(dsaa){
            obj.departmentList = dsaa
          }else{
            resuly.one=''
          }
        }
        
      //档次2
      }else if(sequence==2){
        var kikjl =0
        resuly.two = JSON.stringify(obj.onevalue)
        for(var i = 0;i<listdep.length;i++){
          if(i!=listindex){
            if(resuly.two>=(parseInt(listdep[i].two)-1)&&resuly.two<=(parseInt(listdep[i].two)+1)){
              ElMessage.error('每个档位分数差距不能低于2分')
              // break
              return false
            }
          }
          if(listdep[i].two !=''){
            kikjl+=1
            if(kikjl>obj.dangci2){
              obj.dangciBeyond2 = false
            }
          }
        }
        //判断打分档次是否超出
        if(obj.dangciBeyond2){
          if(resuly.two<90&&resuly.two>=80){
            resuly.F_Result=resuly.two
            resuly.one=''
            resuly.two+='分'
            
            resuly.three=''
            localStorage.setItem('departmentList',JSON.stringify(obj.departmentList))
          }else{
            ElMessage.error('二档分数在89分至80分区间内')
            
            // resuly.two=''
            var dsaa = JSON.parse(localStorage.getItem('departmentList'))
            if(dsaa){
              obj.departmentList = dsaa
            }else{
              resuly.two=''
            }
          }
        }else{
          ElMessage.error('二档只能有'+obj.dangci2+'个')
          obj.dangciBeyond2 = true
          var dsaa = JSON.parse(localStorage.getItem('departmentList'))
          if(dsaa){
            obj.departmentList = dsaa
          }else{
            resuly.two=''
          }
        }
        
      //档次3
      }else if(sequence==3){
        var kikjl =0
        resuly.three = JSON.stringify(obj.onevalue)
        for(var i = 0;i<listdep.length;i++){
          if(i!=listindex){
            if(resuly.three>=(parseInt(listdep[i].three)-1)&&resuly.three<=(parseInt(listdep[i].three)+1)){
              ElMessage.error('每个档位分数差距不能低于2分')
              // break
              return false
            }
          }
          if(listdep[i].three !=''){
            kikjl+=1
            if(kikjl>obj.dangci3){
              obj.dangciBeyond3 = false
            }
          }
        }
        if(obj.dangciBeyond3){
          if(resuly.three<80&&resuly.three>=70){
            resuly.F_Result=resuly.three
            resuly.one=''
            resuly.two=''
            resuly.three+='分'
            localStorage.setItem('departmentList',JSON.stringify(obj.departmentList))
          }else{
            ElMessage.error('三档分数在79分至70分区间内')
            
            // resuly.three=''
            var dsaa = JSON.parse(localStorage.getItem('departmentList'))
            if(dsaa){
              obj.departmentList = dsaa
            }else{
              resuly.two=''
            }
          }
        }else{
          ElMessage.error('三档只能有'+obj.dangci3+'个')
          obj.dangciBeyond3 = true
          var dsaa = JSON.parse(localStorage.getItem('departmentList'))
          if(dsaa){
            obj.departmentList = dsaa
          }else{
            resuly.two=''
          }
        }
      }
      obj.iskeybord = false
      obj.oneindex = 1000
    }
    //输入分数
    function enterin (e){
      var sequence = obj.inputindex
      var listindex = obj.listindex
      if(sequence==1){
        obj.departmentList[listindex].one+=e
        if(obj.departmentList[listindex].one>100){
          obj.departmentList[listindex].one ='100'
        }
      }else if(sequence==2){
        obj.departmentList[listindex].two+=e
        if(obj.departmentList[listindex].two>90){
          obj.departmentList[listindex].two ='89'
        }
      }else if(sequence==3){
        obj.departmentList[listindex].three+=e
        if(obj.departmentList[listindex].three>80){
          obj.departmentList[listindex].three ='79'
        }
      }
      
    }
    //删除分数
    function deletew(){
      var sequence = obj.inputindex
      var listindex = obj.listindex
      var value = obj.departmentList[listindex]
      var value1 =value.one
      var value2 =value.two
      var value3 =value.three
      // alert(obj.departmentList[listindex].three)
      if(sequence==1){
        obj.departmentList[listindex].one = value1.slice(0,value1.length-1)
      }else if(sequence==2){
        obj.departmentList[listindex].two = value2.slice(0,value2.length-1)
      }else if(sequence==3){
        obj.departmentList[listindex].three = value3.slice(0,value3.length-1)
      }
    }
    let bss = ref(0);
    function cutdown(){
      if(obj.gajklas){
        bss.value = setPromiseInterval(()=> {
          obj.Countdown -=1
          if(obj.Countdown<=0){
            gotransfer()
          }
        }, 1000)
      }
    }
    //页面跳转
    function gotransfer(){
      var suiji = route.query.F_Number?route.query.F_Number:''//随机数
      var benums = route.query.WhatPosition?route.query.WhatPosition:''//随机数
      var F_name = route.query.F_name?route.query.F_name:''//随机数
      var fin_id = route.query.F_id?route.query.F_id:''//随机数
      var canEnters = route.query.canEnters?route.query.canEnters:''
      console.log(fin_id)
      localStorage.setItem('fin_id',fin_id)
      if(obj.gajklas){
        obj.tijiaoindex+=1
        
        router.push({path:'/',query:{
            random:suiji,
            WhatPosition:benums,
            F_name:F_name,
            canEnter:canEnters
        }});
        localStorage.setItem('pupnum1','')
        localStorage.setItem('pupnum2','')
        localStorage.setItem('pupnum3','')
      }else{
        obj.pupwindow = false
      }
      
    }
    //
    function getdeplist (){
      var f_id = route.query.F_id?route.query.F_id:''
      deplist({
        groupId:f_id
      }).then(res=>{
        var list  = res.data
        var suiji = route.query.F_Number?route.query.F_Number:''//随机数
        var what = route.query.WhatPosition?route.query.WhatPosition:''//职位

        var passs = []
        for(var i=0;i<list.length;i++){
          passs.push({
            F_DepName:list[i].F_DepName,
            F_DepId:list[i].F_DepId,
            F_GroupId:list[i].F_GroupId,
            F_Id:list[i].F_Id,
            F_ProjectId:list[i].F_ProjectId,
            F_Category:what,
            F_Result:'',
            F_GradeId:'',
            F_RanId:suiji,
            one:'',
            two:'',
            three:'',
            
          })
        }
       
        var ppp = JSON.parse(localStorage.getItem('departmentList'))
        var fff14 = localStorage.getItem('fin_id')
        var fin_id = route.query.F_id?route.query.F_id:''
        // obj.pupnum1 = JSON.parse(localStorage.getItem('pupnum1'))
        // obj.pupnum2 = JSON.parse(localStorage.getItem('pupnum2'))
        // obj.pupnum3 = JSON.parse(localStorage.getItem('pupnum3'))
        console.log(fff14 === fin_id)
        if(fff14 === fin_id){
          obj.departmentList = ppp
        }else{
          obj.departmentList = passs
        }
        var listdep = obj.departmentList
        
        
      })
    }
    //获取档次位列
    function getroleList(){
      var f_id = route.query.F_id?route.query.F_id:''
      obj.jkljkl = route.query.F_name?route.query.F_name:''
      countlist({
        groupId:f_id
      }).then(res=>{
        obj.Gear = res.data
        obj.dangci1 = res.data[0].F_count
        obj.dangci2 = res.data[1].F_count
        obj.dangci3 = res.data[2].F_count
      })
    }
    const router = useRouter();
    //提交分数并跳转
    function gosubmit(){
      
      var datalist = obj.departmentList
      var what = route.query.WhatPosition?route.query.WhatPosition:''//职位
      for(var i =0;i<datalist.length;i++){
        datalist[i].F_Category = what
        if(datalist[i].F_Result==null||datalist[i].F_Result===''){
          obj.istrue= false
          ElMessage.error('请完成全部打分')
          return false
        }else{
          
        }
      }
      if(obj.istrue){
        
        savescorelist({
          entitys:JSON.stringify(obj.departmentList)
        }).then(res=>{
          if(res.code==200){
              obj.pupwindow = true
              obj.gajklas = true
              cutdown()
          }else{
              obj.pupwindow = true
          }
        })
      }
      
      // obj.pupwindow = true
      // obj.gajklas = true
      
      // router.push({path:'/submit',query: {isuccse:'成功'}});
    }
    function chanindex(p,i,k){
      if(p.type){
        obj.oneindex = i
        obj.onevalue = p.num
        obj.quedinglkj = k//判断哪个数字应该覆盖
      }else{
        ElMessage.error('该分数已被选中')
      }
      
    }
    function getprolist(){
      var dd = localStorage.getItem('F_Year')
      obj.ringtitles[1] = dd +'年民主评议打分表'
    }
    onMounted(()=>{
      getroleList()
      getdeplist()
      getprolist()
    })
    
    onUnmounted(() => {
      clearPromiseInterval(bss.value)
    })
    watch(
      
      ()=>obj.departmentList,(dd,pre)=>{
        // alert(1111)
        var num1 = obj.pupnum1
        var num2 = obj.pupnum2
        var num3 = obj.pupnum3
        //做一的判断
        for(var cc=0;cc<num1.length;cc++){
          var ooo2 = 0
          for(var i=0;i<dd.length;i++){
            if(dd[i].one.split('分')[0]==JSON.stringify(num1[cc].num)){
              console.log(dd[i].two.split('分')[0])
              num1[cc].type = false
            }else{
              ooo2+=1
              if(ooo2>dd.length-1){
                num1[cc].type = true
              }
            }
          }
        }
        //做二的判断
        for(var cc=0;cc<num2.length;cc++){
          var ooo2 = 0
          for(var i=0;i<dd.length;i++){
            if(dd[i].two.split('分')[0]==JSON.stringify(num2[cc].num)){
              console.log(dd[i].two.split('分')[0])
              num2[cc].type = false
            }else{
              ooo2+=1
              if(ooo2>dd.length-1){
                num2[cc].type = true
              }
            }
          }
        }
        //做三的判断
        for(var cc=0;cc<num3.length;cc++){
          var ooo2 = 0
          for(var i=0;i<dd.length;i++){
            if(dd[i].three.split('分')[0]==JSON.stringify(num3[cc].num)){
              console.log(dd[i].two.split('分')[0])
              num3[cc].type = false
            }else{
              ooo2+=1
              if(ooo2>dd.length-1){
                num3[cc].type = true
              }
            }
          }
        }
        
      },{
        deep:true
      }
        
    )
    return{
      ...toRefs(obj),
      junpll,
      PuKeyboard,
      enterin,
      deletew,
      gosubmit,
      gotransfer,
      confirmqq,
      chanindex
    }
  },
  methods:{
  }
}
</script>

<style lang="less">
  @import '../css/phys.less';
</style>
